<template>
  <div
    :class="['page', page_size]"
    ref="page"
    :style="bg_var"
  >
    <h2>{{ description }}</h2>
    <qr-generator v-if="place" :text="url" class="qr-gen" />

    <h2 class="real_name">{{ place.name }}</h2>
    <div :class="['image-bottom', bottom_pos]" v-if="logo">
      <img :src="logo.image" class="logo" />
    </div>
  </div>
</template>

<script>
import { toRefs } from "@vue/reactivity";
import QrGenerator from "./QrGenerator.vue";
import { computed } from "@vue/runtime-core";
export default {
  components: { QrGenerator },
  props: {
    place: {
      type: Object,
      required: true,
    },
    page_size: {
      type: String,
      default: "a4",
      required: false,
    },
    description: {
      required: false,
      default: "",
      type: String,
    },
    logo: {
      type: Object,
      required: false,
      default: null,
    },
    background: {
      type: Object,
      required: false,
      default: null,
    },
    color: {
      type: String,
      default: "#000000",
      required: false,
    },
    bottom_pos: {
      type: String,
      default: "right",
      required: false,
    },
  },
  setup(props) {
    const { place, background, color } = toRefs(props);
    const url = computed(
      () => `${document.location.origin}/#/?place=${place.value.id || ""}`
    );
    const bg_var = computed(() => {
      let ret = { "--color": color.value };
      if (background.value) {
        ret["--bg-image"] = `url(${background.value.image})`;
      }
      return ret;
    });
    return { url, bg_var };
  },
};
</script>

<style lang='scss'>
.page {
  $ratio: 1.4121;
  $font-size-mult: 50;
  border: 1px solid black;
  width: 50vw;
  max-width: 400px;
  height: 50vw * $ratio;
  max-height: 400px * $ratio;
  font-size: (400px * $ratio) / $font-size-mult;
  display: flex;
  flex-direction: column;
  background-image: var(--bg-image);
  background-size: cover;
  color: var(--color);

  break-after: always;
  &.has-image {
    justify-content: space-between;
  }
  &:not(.has-image) .real_name {
    margin-bottom: auto;
  }

  .image-bottom {
    width: 100%;
    height: 10%;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: unset;
    justify-content: flex-end;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    &.middle {
      justify-content: center;
    }
    .logo {
      position: relative;
      height: 100%;
    }
  }
  @media screen and (min-width: 800px) {
    & {
      font-size: 400px / $font-size-mult;
    }
  }
  h2 {
    font-size: 4em;
    padding: 0.05em;
    margin: 0;
    margin-top: 0.05em;
    color: var(--color);
    max-height: 4.3em;
  }
  .qr-gen {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  @media print {
    & {
      max-width: inherit !important;
      font-size: 21mm;
      margin: 0;
      padding: 0;
      page-break-after: always;
      break-after: always;
      border: 0;
    }
    $sizes: (
      "a4": 210mm,
      "a5": 148mm,
      "a6": 105mm,
      "a7": 74mm,
    );
    @each $size, $value in $sizes {
      &.#{$size} {
        width: $value;
        height: $value * $ratio - ($value / 21);
        min-height: $value * $ratio - ($value / 21);
        font-size: $value / $font-size-mult;
      }
    }
  }
}
</style>
