<template>
  <div class="qr-gen" ref="body">
    <canvas ref="qr" class="canvas" />
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  props: {
    text: String,
  },
  data() {
    return {
      size: 1000,
    };
  },
  mounted() {
    this.update();
    this.size = this.$refs.body.offsetWidth;
    window.addEventListener("resize", () => {
      this.size = this.$refs.body.offsetWidth;
    });
    this.$watch(() => JSON.stringify([this.text, this.size]), this.update);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.$forceUpdate);
  },
  methods: {
    update() {
      QRCode.toCanvas(this.$refs.qr, this.text, {
        errorCorrectionLevel: "H",
        width: this.size,
      }).then(() => {
        // переобределим размер канвса
        this.$refs.qr.style.width = "100%";
        this.$refs.qr.style.height = "100%";
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/static/scss/_variables";
.qr-gen {
}
</style>