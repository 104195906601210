import {ref, computed, onMounted, onBeforeUnmount} from 'vue';
import {useStore} from "vuex";
import {throttle} from 'lodash-es'
import {LOGOUT_TIMEOUT, TIMEOUT_DEBOUNCE} from "../constants";
import {useRoute, useRouter} from "vue-router";

const EVENT_NAME = 'mousemove';

export default function useTimeotLogout() {
  let last_check = ref(null);
  let listner = ref(false);

  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const has_auth = computed(() => store.getters.has_token);
  const user_data = computed(() => store.getters.user_data);

  const timeout_debounce = computed(() => user_data.value.timeout_debounce || TIMEOUT_DEBOUNCE);
  const timeout = computed(() => (user_data.value.timeout || LOGOUT_TIMEOUT) * 1000);
  const use_background_check = computed(() => user_data.value.use_background_check !== undefined  ? user_data.value.use_background_check : true);

  const _event_handler = () => {
    let current_dt = Date.now();
    const dt_diff = current_dt - last_check.value;
    const timeout_diff = timeout.value - timeout_debounce.value;
    if (isNaN(timeout_diff)) {
      return
    }
    if (dt_diff < timeout_diff) {
      last_check.value = current_dt;
    } else {
      if (has_auth.value)
        store.dispatch('logout').then(() => {
          last_check.value = current_dt;
          if (route.name !== 'Login')
            router.push({name: 'Login'});
        })
    }
  }
  const event_handler = throttle(_event_handler, 1000);

  const addListner = () => {
    if (!use_background_check.value) return;
    console.log('addListner');
    if (!listner.value) {
      last_check.value = Date.now();
      window.addEventListener(EVENT_NAME, event_handler);
      listner.value = true;
    }
  }

  const removeListner = () => {
    last_check.value = null;
    if (!use_background_check.value) return;
    console.log('removeListner');
    if (listner.value) {
      window.removeEventListener(EVENT_NAME, event_handler);
      listner.value = false;
    }
  }

  const registerListner = () => {
    console.log('registerListner');
    onMounted(() => {
      console.log('mounted', has_auth.value)
      if (has_auth.value) {
        addListner()
      }
    });

    onBeforeUnmount(() => {
        removeListner()
    });
  }

  return {addListner, removeListner, registerListner}
}
