<template>
  <div class="generator-page">
<!--    <login-form v-show="!has_auth" />-->

    <div v-if="print_mode" class="print-div">
      <button @click="print_mode = !print_mode" class="print-btn">
        Return
      </button>
      <page
        v-for="p in real_place"
        :key="p.id"
        :place="p"
        :logo="image"
        :color="color"
        :background="bg"
        :page_size="page_size"
        :description="description"
        :bottom_pos="bottom_pos"
      />
    </div>
    <div v-show="!print_mode" class="editor container">
      <div class="col">
<!--        <select v-model="place" placeholder="Place" multiple class="form-select h-100 mselect" ref="select" size="20">-->
<!--          <option v-for="p in places" :key="p.id" :value="p.id">-->
<!--            {{ p.name }}-->
<!--          </option>-->
<!--        </select>-->
        <div class="h-100 mselect" >
          <div class="places-list">
          <div class="btn-group w-100" role="group" v-for="p in places" :key="p.id">
            <input class="btn-check" type="checkbox" v-model='place' :value="p.id" autocomplete="off" :id="`place_${p.id}`"/>
            <label class="btn btn-outline-primary  w-100" :for="`place_${p.id}`">{{p.name}}</label>
            <input class="btn-check" type="checkbox"  autocomplete="off" :id="`place_show_${p.id}`" :value="p.id" v-model="show_places"/>
            <label :class="['btn', p.show ? 'btn-warning' : 'btn-danger']" :for="`place_show_${p.id}`"
              @click="toggle_place(p.id, !p.show)"
            >
              <svg v-if='p.show' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
              </svg>
            </label>
          </div>
            </div>
        </div>
      </div>
      <div class="col-5">
      <form class="form w-100">
        <h2>Генератор</h2>
        <div class="md-3">
          <input type="color" v-model="color" class="form-control"/>
        </div>

        <div class="md-3">
          Logo
          <select v-model="current_image_id" placeholder="Place" class="form-select">
            <option value="">Select logo</option>
            <option v-for="p in images" :key="p.id" :value="p.id">
              {{ p.name }}
            </option>
          </select>
        </div>
        <div class="md-3">
          Background image
          <select v-model="current_bg_id" placeholder="Place" class="form-select">
            <option value="">Select background</option>
            <option v-for="p in images" :key="p.id" :value="p.id">
              {{ p.name }}
            </option>
          </select>
        </div>
        <div class="md-3" v-if="current_image_id" >
          Position
          <br/>
          <div class="form-check form-check-inline">
            <input id='pos_1' class='form-check-input' type="radio" value="left" v-model="bottom_pos" />
            <label for='pos_1' class='form-check-label'>Left</label>
          </div>
          <div class="form-check form-check-inline">
            <input  id='pos_2' class="form-check-input"
              type="radio"
              value="middle"
              v-model="bottom_pos"
            />
          <label for='pos_2' class="form-check-label">Center</label>
          </div>
          <div class="form-check form-check-inline">
            <input id='pos_3' class="form-check-input"
              type="radio"
              value="right"
              v-model="bottom_pos"
            />
          <label for='pos_3' class="form-check-label">Right</label>
          </div>
        </div>
        <div class="md-3">Upload file</div>
        <div class="md-3 upload-row">
          <input type="file" class="form-control" style="width: 80%" @input="add_file" />
          <button
            v-if="file_to_upload"
            type="button"
            style="width: 20%"
            @click="upload_file"
          >
            Upload
          </button>
        </div>
        <div class="md-3">
          Page size
          <select v-model="page_size" class="form-select">
            <option value="a4">A4</option>
            <option value="a5">A5</option>
            <option value="a6">A6</option>
            <option value="a7">A7</option>
          </select>
        </div>

        <div class="md-3">
          Description
          <textarea class="form-control" v-model="description" />
        </div>
        <div class="md-3" placeholder="Commentary">
          <button @click="go_print()" class="btn btn-primary w-100 btn-print">
            Print
          </button>
        </div>
        <div class="md-3 logout-row">
          <button class="btn btn-outline-danger" @click="logout()">Logout</button>
        </div>
      </form>
        </div>
      <div class="md-3">
      <page
          v-if="real_place.length"
        :place="real_place[0]"
        :logo="image"
        :color="color"
        :background="bg"
        :page_size="page_size"
        :description="description"
        :bottom_pos="bottom_pos"
        ref="page"
      />
      <div class="page fake" v-if="real_place.length == 0">
        <h2>{{ description }}</h2>
      </div>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
//import LoginForm from "../components/LoginForm.vue";
import Page from "../components/Page.vue";
import { useRoute, useRouter } from "vue-router";
import useTimeotLogout from "../modules/timeoutLogout";
import {FETCH_IMAGES_TIMEOUT, FETCH_PLACES_TIMEOUT} from "../constants";
export default {
  components: {  Page },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const {registerListner} = useTimeotLogout();

    onMounted(() => {
      if (route.query.token) {
        store.commit("set_token", route.query.token);
      }
      store.dispatch("check_status")
    });

    const has_auth = computed(() => store.getters.has_token);

    // места
    let _places = ref([]);
    let place = ref([]);
    let show_places = ref([]);
    let places = computed(() => _places.value.filter(p => p.name != '' && p.name != null), to => _places.value=to);
    let place_update = ref(null);

    const fetch_places = () => {
      if (store.getters.has_token)
      store.dispatch("request", {
        url: "place",
        method: "GET",
      }).then(
        r => {
          _places.value = r;
          show_places.value = r.filter(p=>p.show).map(p => p.id);
          //place.value = r.map(r=>r.id).slice(0, 1)
        }
      )
    };

    const replace_place = p => {
      const find_index = _places.value.findIndex(
        _p => _p.id === p.id
      );
      if (find_index != -1) {
        _places.value.splice(find_index,1,p);
      }
    };
    const toggle_place = (place_id, value) =>{
      place_update.value = place_id;
      store.dispatch(
        'req', {
          url: `place/${place_id}/`,
          method: 'PATCH',
          data: {
            show: value
          }
        }
      ).then(r => {
        place_update.value = null;
        if (r.ok) {
          return r.json()
        }
      }).then(
        data => replace_place(data)
      )
    };


    const url = (p) => `${document.location.origin}/#/?place=${p.id || ""}`;
    const real_place = computed(() => {
      let ret = [];
      ret = places.value.filter((p) => place.value.indexOf(p.id) != -1);
      return ret;
    });

    let color = ref("#000000");
    // дополнительный текст
    let description = ref("Проблема? Напишите!");
    let page = ref(null);

    const load_text = () => {
      store.dispatch('req', {
        url: 'settings/text/',
        method: 'GET',
      }).then(r=>r.json()).then(
        r=> {
          description.value = r.value

        }
      )
    }
    onMounted(fetch_places);
    onMounted(load_text);
    // onMounted(() => {
    //   const w = page.value.offsetWidth;
    //   console.log(page.value, w);
    //   page.value.style.height = w * 1.618;
    // });
    // watch(place, () => (description.value = ""));

    // картинки
    let current_image_id = ref("");

    let current_bg_id = ref("");
    let images = ref([]);
    const image = computed(() =>
      images.value.find((i) => i.id == current_image_id.value)
    );
    const bg = computed(() => {
      const img = images.value.find((i) => i.id == current_bg_id.value);
      return img;
    });
    // загрузим картинки
    const load_images = () => {
      if (store.getters.has_token)
        store.dispatch("request", {
          url: "images",
          method: "GET",
        }).then(r=> {
          images.value=r
          const def_image = images.value.find(i => i.default)
          if (def_image) {
            current_image_id.value = def_image.id
          }
        });
    };

    const page_size = ref("a4");
    onMounted(load_images);

    const file_to_upload = ref(null);
    const add_file = (event) => {
      file_to_upload.value = event.target.files[0];
    };
    const upload_file = () => {
      store
        .dispatch("upload_file", {
          name: "Test Upload",
          file: file_to_upload.value,
        })
        .then((r) => r.json())
        .then((data) => {
          images.value.push(data);
          current_image_id.value = data.id;
        });
    };
    let print_mode = ref(false);
    let bottom_pos = ref("right");

    const go_print = () => {
      print_mode.value = !print_mode.value
      const url = `images/${current_image_id.value}/`
      Promise.all([
        store.dispatch(
          'req',
          {
            url,
            method: 'PATCH',
            data: {
              default: true
            }
          }
        ),
        store.dispatch('req', {
          url: 'settings/text/',
          method: 'PATCH',
          data: {
            value: description.value
          }
        })])
    }

    let select = ref(null);
    //onMounted(()=>select.value.focus())
    watch(place, () => {
      const t = bottom_pos.value;
      bottom_pos.value = t;
    })


    const logout = () => {
      if (confirm('Are You really want logout?')) {
        store.dispatch('logout').then(
          () =>{
            router.push({name: 'Login'})
          }
        )
      }
    }
    onMounted(() => {
      setInterval(load_images, FETCH_IMAGES_TIMEOUT);
      setInterval(fetch_places, FETCH_PLACES_TIMEOUT);
    });

    registerListner();

    return {
      select,
      print_mode,
      color,go_print,
      current_bg_id,
      bg,
      bottom_pos,
      add_file,
      upload_file,
      file_to_upload,
      page_size,
      page,
      has_auth,
      places,
      place,
      url,
      description,
      real_place,
      images,
      current_image_id,
      image,
      logout,
      show_places,
      toggle_place
    };
  },
};
</script>

<style lang="scss">
@import "~@/static/scss/_variables";
.generator-page {
  .places-list {
    max-height: 564.84px;
    overflow-y: auto;
    padding-left: 1px;
  }
  .btn-print {
    margin-top: 0.5em;
  }
  .logout-row {
    padding-top: 5%;
    display: flex;
    align-items: flex-end;
    .btn {
      margin-left: auto;
    }
  }
  .mselect {
    -webkit-appearance: none;
    border-bottom: var(--bs-primary) 1px solid;
    border-top: var(--bs-primary) 1px solid;
    border-radius: 2px;
  }
  @media print {
    & > botton {
      display: none;
    }
  }
  .editor {
    padding-top: 2em;
    display: flex;
    justify-content: center;
    @media print {
      & {
        flex-direction: column;
      }
    }
    .places {
      @media print {
        & {
          display: none;
        }
      }
      select {
        height: 100%;
      }
    }
    .form {
      margin: 0;
      padding: 0.5rem 2rem;
    }
  }
}
@media print {
  .form,
  .print-btn {
    display: none;
  }
}
.row.position {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  flex-grow: 1;

}
.upload-row {
  display: flex;
  flex-direction: row;
}
.print-div {
  @media screen {
    display: flex;
    align-items: center;
    flex-direction: column;
    .print-btn {
      position: fixed;
      top: 1em;
      left: 50%;
      transform: translate(-50%, 50%);
      z-index: 1000;
    }
  }
  @media print {
    display: table;
  }
}
</style>
